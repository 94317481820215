<template>
    <div class="gauge">
        <!-- 列表 -->
        <div class="region">
            <div class="info">
                <!-- <div class="infoBox"> -->
                    <p class="title">{{ evaluationInfo.name }}</p>
                    <p>完成进度：{{ evaluationInfo.completeGaugeCount }}/{{ evaluationInfo.gaugeCount }}</p>
                    <p>截止时间：{{ evaluationInfo.endTime }}</p>
                <!-- </div> -->
            </div>
            <div class="rg">
                <div class="list">
                    <div class="item" v-for="item in tableData" :key="item.id">
                        <div class="itemLeft">
                            <p class="title">{{ item.name }}</p>
                            <p>{{ item.completeProblem }}/{{ item.problemCount }}</p>
                        </div>
                        <div class="itemRight">
                            <!-- <button v-if="item.completeGaugeCount === 0" @click="todo(item)">开始</button>
                        <button v-else-if="item.completeGaugeCount === item.gaugeCount && item.isRepeat === 0" class="completed">已完成</button>
                         <button v-else-if="item.completeGaugeCount === item.gaugeCount && item.isRepeat === 1">再次测评</button>
                        <button v-else-if="item.completeGaugeCount < item.gaugeCount" @click="todo(item)">继续</button> -->

                            <button v-if="item.completeProblem === 0" @click="todo(item, 1)">开始</button>
                             <button v-else-if="item.isComplete === false"
                                @click="todo(item, 2)">继续</button>
                            <button v-else-if="item.completeProblem === item.problemCount && item.isRepeat === 0"
                                class="completed">已完成</button>
                            <button v-else-if="item.completeProblem === item.problemCount && item.isRepeat === 1"
                                @click="todo(item, 1)">再次测评</button>
                            <button v-else-if="item.completeProblem < item.problemCount"
                                @click="todo(item, 2)">继续</button>
                        </div>
                    </div>
                    <!-- <div class="item">
                        <div class="itemLeft">
                            <p class="title">量表名称量表名称1</p>
                            <p>0/90</p>
                        </div>
                        <div class="itemRight">
                            <button>开始</button>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="toBottom" v-if="tableData.length > 0">
                没有更多了...
            </div>
        </div>
    </div>
</template>

<script>
import { getGauge } from '@/api/evaluation'
export default {
    data() {
        return {
            evaluationInfo: null, // 计划信息
            tableData: [],
            userId:null
        }
    },
    methods: {
        todo(val, val2) {
            console.log(val)
            // this.$router.push('/home/guidelines');
            this.$router.push({
                path: '/home/guidelines'
            })
            let GaugeInfo = {
                planId: this.evaluationInfo.id,
                type: val2,
                ...val

            }
            localStorage.setItem('GaugeInfo', JSON.stringify(GaugeInfo));
            localStorage.setItem('path', '/home/gauge')

        },
        // 根据计划id获取量表列表
        async getGaugeList() {
            const res = await getGauge({ planId: this.evaluationInfo.id, isMerge: this.evaluationInfo.isMerge, id: this.userId });
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data
            }
        }
    },
    created() {
        // console.log(this.$route.query)
        // this.evaluationInfo = this.$route.query;
        this.evaluationInfo = JSON.parse(localStorage.getItem('planInfo'));
        if (localStorage.getItem('patientId') !== 'null' && localStorage.getItem('patientId')) {
            this.userId = localStorage.getItem('patientId')
        } else {
            this.userId = JSON.parse(localStorage.getItem('userInfo')).id;
        }
        this.getGaugeList();
    }
}
</script>

<style lang="scss" scoped>
.gauge {
    display: flex;
    justify-content: center;
    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    // height: 100%;
    // background:  rgb(184, 183, 183);;
    //  background-image: url('../../assets/home/backgroundImage.png');
    // background-repeat: no-repeat;
    // background-position: center;
    // background-attachment: fixed;
    .region {

        // background: white;
        .rg {
            width: 80%;
            margin: auto;
            // height: 100vh;
        }

        p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            color: #7D7D7D;

        }

        .title {
            font-size: 16px;
            font-weight: 400;
            color: #303030;
        }

        .info {
            background: var(--banner-color);
           
            width: 100%;
            // padding: 50px 100px;
            box-sizing: border-box;
            // .infoBox {
            //     margin: auto;
            //     width: 80%;
            //     height: 100%;
            //     // display: flex;
            //     // display: flex;
            //     // flex-wrap: wrap;
            //     // // align-items: center;
            //     // align-items: flex-end;
            //     // // align-items: ;
            //     // justify-content: center;
            //     // padding-bottom: 10px;
            //     box-sizing: border-box;
            //     // padding-top: 15px;
            //     p {
            //         width: 100%;
            //         // margin-top: 10px;
            //         &:nth-child(1) {
            //             margin-top: 0px;
            //         }
            //     }
            // }

            p:nth-child(1) {
                margin-top: 0;
                // width: 100%;
                // line-height: ;
                // margin-top: 15px;
            }
            .title {
            // font-size: 16px;
            font-weight: 600;
            // color: #303030;
        }
            

            margin-bottom: 20px;
        }

        .list {
            .item {
                margin: auto;
                // box-shadow: 0px 2px 4px 0px rgba(148, 146, 146, 0.25);
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1700);
                height: 110px;
                width: 100%;
                margin-bottom: 20px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                padding: 10px 15px;
                box-sizing: border-box;
                justify-content: space-between;
                margin-bottom: 20px;

                .itemRight {
                    button {
                        cursor: pointer;
                        border: none;
                        width: 90px;
                        height: 35px;
                        background: var(--custom-color);
                        border-radius: 6px;
                        color: white;
                        font-size: 14px;
                    }

                    .completed {
                        opacity: 0.5;
                        background: #F08457;
                    }
                }
            }
        }
    }
}
.toBottom{
    padding-top: 25px;
    box-sizing: border-box;
    height: 60px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #7D7D7D;
}

@media screen and (min-width: 751PX) {
    .info{
        // padding-bottom: 30px;
        padding: 50px 100px;
    }
    .region {
        width: 900px;
        // height: 100%;
        background: white;
        .info{
            height: 200px;
            
            // .infoBox {
            //     display: flex;
            //     flex-wrap: wrap;
            //     // align-items: center;
            //     align-items: flex-end;
            //     // align-items: ;
            //     justify-content: center;
            //     // padding-bottom: 10px;
            //     // padding-top: 15px;
            // }

            p {
                // width: 100%;
                // line-height: ;
                margin-top: 20px;
            }
        }
        // .rg {
        //     //  height: calc(100vh - 80px);
        // }
    }
}

@media screen and (max-width: 750PX) {
    
    .region {
        width: 100%;
        // height: 100vh;
        .info{
        // padding-bottom: 10px;
        padding: 30px 40px;
        p{
            margin-top: 10px;
        }
        // padding: 30px ;
    }
    }
    .info{
         height: 150px;
    }
}
</style>